import { AuthenticationService } from './../../auth/authentication.service';
import { Guid } from 'guid-typescript';
import { Component, OnInit, ViewChild, HostListener, ViewChildren, QueryList } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { PerfectScrollbarDirective, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AppSettings } from '../../app.settings';
import { Settings } from '../../app.settings.model';
import { rotate } from '../../theme/utils/app-animation';
import { MenuService } from '../../theme/components/menu/menu.service';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss'],
  animations: [rotate],
  providers: [MenuService]
})
export class AuthLayoutComponent implements OnInit {
  @ViewChild('sidenav') sidenav: any;
  @ViewChild('backToTop') backToTop: any;
  @ViewChildren(PerfectScrollbarDirective) pss: QueryList<PerfectScrollbarDirective>;
  public setingToggle: boolean = true;
  public optionsPsConfig: PerfectScrollbarConfigInterface = {};
  public settings: Settings;
  public showSidenav: boolean = false;
  public showInfoContent: boolean = false;
  public toggleSearchBar: boolean = false;
  currentUserRole: string = null;
  currentUserSubscription: Guid;
  private currentUserLogo;
  returnUrl: string = null;
  embed: boolean = false;

  constructor(public appSettings: AppSettings,
    public router: Router,
    private menuService: MenuService,
    private auth: AuthenticationService) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.optionsPsConfig.wheelPropagation = false;
    if (window.innerWidth <= 960) {
      this.settings.menu = 'vertical';
      this.settings.sidenavIsOpened = false;
      this.settings.sidenavIsPinned = false;
    }
    this.currentUserRole = localStorage.getItem('currentUserRole');
    this.currentUserSubscription = localStorage.currentUserSubscription;
    this.returnUrl = localStorage.returnUrl;
    this.embed = localStorage.embed && localStorage.embed.toLowerCase() == true.toString().toLowerCase();
    this.currentUserLogo = localStorage.currentUserLogo || "";
  }

  ngAfterViewInit() {
    setTimeout(() => { this.settings.loadingSpinner = false }, 300);
    this.backToTop.nativeElement.style.display = 'none';
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.scrollToTop();
      }
      if (window.innerWidth <= 960) {
        this.sidenav.close();
      }
    });
    if (this.settings.menu == "vertical") {
      this.menuService.getVerticalMenuItems().then((menuItems) => {
        this.menuService.expandActiveSubMenu(menuItems);
      });
    }
    //this.menuService.expandActiveSubMenu(this.menuService.getVerticalMenuItems());
  }

  public toggleSidenav() {
    this.sidenav.toggle();
    this.settings.sidenavIsOpened = true;
  }

  public closeInfoContent(showInfoContent) {
    this.showInfoContent = !showInfoContent;
  }

  public onPsScrollY(event) {
    (event.target.scrollTop > 300) ? this.backToTop.nativeElement.style.display = 'flex' : this.backToTop.nativeElement.style.display = 'none';
  }

  public scrollToTop() {
    document.getElementById('main').scrollTop = 0;
    // this.pss.forEach(ps => {
    //   if (ps.elementRef.nativeElement.id == 'main') {
    //     ps.scrollToTop(0, 250);
    //   }
    // });
  }

  public closeSubMenus() {
    if (this.settings.menu == "vertical") {
      this.menuService.closeAllSubMenus();
    }
  }

  private backToUrlSystem() {
    this.auth.logout();
    //window.location.href = this.returnUrl;
  }
}
