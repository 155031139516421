import { Attachment } from './../shared-component/attachment-dialog/attachment.model';
import { Guid } from 'guid-typescript';
export class SessionUser {
    userName!: string;
    firstName!: string;
    expiryDate!: string;
    token?: string;
    userId?: Guid;
    services?: SessionProfileService[];
    roles?: SessionServiceRole[];
    personPic?: Attachment;
}

export class SessionServiceRole {
    serviceId: Guid;
    name?: string;
}

export class SessionService {
    id: Guid;
    name?: string;
    customName?: string;
    code?: string;
    startDate: Date;
    endDate: Date;
    serviceKindId?: string;
    subscriptionId: Guid;
    isDelete: boolean;
    serviceTypeId?: string;
}

export class SessionProfileService {
    profileId: Guid;
    service: SessionService
}