import { Guid } from 'guid-typescript';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from './authentication.service';
import { resolve } from 'path';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    private sessionUserSubscription;
    private sessionUserService;
    private sessionUserRole;
    currentUserSubscription: Guid;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            const sessionUser = this.authenticationService.currentUserValue;
            if (sessionUser && sessionUser.token && sessionUser.roles && sessionUser.roles.length > 0 && sessionUser.services && sessionUser.services.length > 0) {
                // check if route is restricted by role
                this.sessionUserService = localStorage.currentUserService || sessionUser.services[0].service.id;
                localStorage.setItem('currentUserService', this.sessionUserService);
                localStorage.setItem('currentUserSubscription', sessionUser.services[0].service.subscriptionId.toString());

                var roles = sessionUser.roles.filter(p => p.serviceId == this.sessionUserService);
                this.sessionUserRole = localStorage.currentUserRole || roles[0].name;

                if (roles.findIndex(p => p.name == this.sessionUserRole) == -1) {
                    this.sessionUserRole = roles[0].name;
                }

                localStorage.setItem('currentUserRole', this.sessionUserRole);

                let currentUserRoleId = JSON.parse(localStorage.getItem('sessionUser')).roles.filter(item => item.name == this.sessionUserRole)[0].id;
                localStorage.setItem('currentUserRoleId', currentUserRoleId);
                // authorised so return true
                this.authenticationService.handlePermission(state.url).subscribe(resolve);
            }
            else {
                // not logged in so redirect to login page with the return url
                this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                reject();
            }
        });
    }
}