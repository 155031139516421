import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthLayoutComponent } from './shared-component/auth-layout/auth-layout.component'
import { PublicLayoutComponent } from './shared-component/public-layout/public-layout.component';
import { BlankComponent } from './shared-component/blank/blank.component';
import { NotFoundComponent } from './shared-component/errors/not-found/not-found.component';
import { ErrorComponent } from './shared-component/errors/error/error.component';
import { AuthGuard } from './auth/auth.guard';

export const routes: Routes = [
    {
        path: '', component: AuthLayoutComponent, children: 
        [
            //{ path: 'constant', loadChildren: () => import('./pages/card/constant/constant.module').then(m => m.ConstantModule), data: { breadcrumb: 'Constant' } },
            { path: 'exams', loadChildren: () => import('./pages/Exam/exam.module').then(m => m.ExamModule), data: { breadcrumb: 'Exams' }, canActivate: [AuthGuard] },
            { path: 'surveys', loadChildren: () => import('./pages/Exam/exam.module').then(m => m.ExamModule), data: { breadcrumb: 'Surveys', ExamType: 3 }, canActivate: [AuthGuard] },
            { path: 'evaluations', loadChildren: () => import('./pages/Exam/exam.module').then(m => m.ExamModule), data: { breadcrumb: 'Evaluations', ExamType: 4 }, canActivate: [AuthGuard] },
            { path: 'mocks', loadChildren: () => import('./pages/Exam/exam.module').then(m => m.ExamModule), data: { breadcrumb: 'Mocks', ExamType: 5 }, canActivate: [AuthGuard] },
            { path: 'setups', loadChildren: () => import('./pages/Setup/setup.module').then(m => m.SetupsModule), data: { breadcrumb: 'Setups' }, canActivate: [AuthGuard] },
            { path: 'evaluations', loadChildren: () => import('./pages/Evaluations/evaluations.module').then(m => m.EvaluationsModule), data: { breadcrumb: 'Evaluations' }, canActivate: [AuthGuard] },
            { path: 'schoolBags', loadChildren: () => import('./pages/SchoolBag/schoolbag.module').then(m => m.SchoolBagModule), data: { breadcrumb: 'School bag' }, canActivate: [AuthGuard] },
            { path: 'dailyAttendance', loadChildren: () => import('./pages/DailyAttendance/dailyAttendance.module').then(m => m.DailyAttendanceModule), data: { breadcrumb: 'Daily attendance' }, canActivate: [AuthGuard] },
            { path: 'rolesPermissions', loadChildren: () => import('./pages/RolePermission/rolepermission.module').then(m => m.RolePermissionModule), data: { breadcrumb: 'Roles permissions' }, canActivate: [AuthGuard] },
            { path: 'liveSessions', loadChildren: () => import('./pages/LiveSessions/liveSessions.module').then(m => m.LiveSessionsModule), data: { breadcrumb: 'Live sessions' }, canActivate: [AuthGuard] },
            { path: 'groups', loadChildren: () => import('./pages/Groups/groups.module').then(m => m.GroupsModule), data: { breadcrumb: 'Groups' }, canActivate: [AuthGuard] },
            { path: 'chat', loadChildren: () => import('./pages/Chat/chat.module').then(m => m.ChatModule), data: { breadcrumb: 'Chat' }, canActivate: [AuthGuard] },
            { path: 'reports', loadChildren: () => import('./pages/Reports/reports.module').then(m => m.ReportsModule), data: { breadcrumb: 'Reports' }, canActivate: [AuthGuard] },
            { path: 'ltm', loadChildren: () => import('./pages/Custom/LTM/ltm.module').then(m => m.LTMModule), data: { breadcrumb: 'LTM' }, canActivate: [AuthGuard] },
            { path: 'ridwan', loadChildren: () => import('./pages/Custom/Ridwan/ridwan.module').then(m => m.RidwanModule), data: { breadcrumb: 'Ridwan' }, canActivate: [AuthGuard] },
            { path: 'blank', component: BlankComponent, data: { breadcrumb: 'Blank page' } },
            { path: 'error', component: ErrorComponent, data: { breadcrumb: 'Error' } },
        ],
        canActivate: [AuthGuard]
    },

    {
        path: '', component: PublicLayoutComponent, children: [
            { path: 'blank', component: BlankComponent, data: { breadcrumb: 'Blank page' } },
            { path: 'login', loadChildren: () => import('./shared-component/login/login.module').then(m => m.LoginModule) },
            { path: 'register', loadChildren: () => import('./shared-component/register/register.module').then(m => m.RegisterModule) },
            { path: 'error', component: ErrorComponent, data: { breadcrumb: 'Error' } },
        ]
    },

    { path: '**', component: NotFoundComponent }
];


@NgModule({
    imports: [
        //RouterModule.forRoot(routes)
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
            relativeLinkResolution: 'legacy',
            // useHash: true
        }),
    ],
    exports: [
        RouterModule,
    ]
})

export class AppRoutingModule { }