import { Guid } from 'guid-typescript';
export class Menu {
    constructor(public id: Guid,
        public title: string,
        public routerLink: string,
        public href: string,
        public icon: string,
        public target: string,
        public hasSubMenu: boolean,
        public parentId?: Guid) { }
}

export class MenuForDatabase {
    constructor(
        public id: number,
        public title: string,
        public routerLink: string,
        public href: string,
        public icon: string,
        public target: string,
        public hasSubMenu: boolean,
        public parentId: number,
        public sortOrder?: number,
        public screenName?: string) { }
} 