import { Menu } from './../../theme/components/menu/menu.model';

import { Guid } from 'guid-typescript';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserRole, SystemScreenNode, FeatureNode } from './rolepermission.model';

@Injectable({
  providedIn: 'root'
})
export class RolepermissionService {

  constructor(public http: HttpClient) { }


  getAllRoles(): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/RolePermission/GetAllRoles`);
  }

  addUserRole(username: string, role: string, serviceId: Guid): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/Account/AddUserRole/${username}/${role}/${serviceId}`, {});
  }

  getUserRolesByUserId(userId: Guid): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/Account/GetUserRolesByUserId/${userId}`);
  }

  deleteUserRole(userId: Guid, roleId: Guid, serviceId: Guid): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/Account/DeleteUserRole/${userId}/${roleId}/${serviceId}`);
  }

  getUsersRoles(): Observable<UserRole[]> {
    return this.http.get<UserRole[]>(`${environment.apiUrl}/Profile/GetUsersRoles`);
  }

  addNewRole(role: UserRole): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/RolePermission/AddNewRole`, role);
  }

  updateRole(roleId: Guid, role: UserRole): Observable<UserRole> {
    return this.http.put<UserRole>(`${environment.apiUrl}/RolePermission/UpdateRole/${roleId}`, role);
  }

  deleteRole(roleId: Guid): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/RolePermission/DeleteRole/${roleId}`);
  }

  getSystemScreens(roleId: Guid): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/RolePermission/GetSystemScreens/${roleId}`);
  }

  giveRoleAccess(roleId: Guid, screen: SystemScreenNode): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/RolePermission/GiveRoleAccess/${roleId}`, screen);
  }

  removeRoleAccess(roleId: Guid, screen: SystemScreenNode): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/RolePermission/RemoveRoleAccess/${roleId}`, screen);
  }

  deletePermission(roleId: Guid, screenId: Guid): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/RolePermission/DeletePermission/${roleId}/${screenId}`);
  }

  getMenuItemsByRoleId(roleId: Guid): Observable<Menu[]> {
    return this.http.get<Menu[]>(`${environment.apiUrl}/RolePermission/GetMenuItemsByRoleId/${roleId}`);
  }

  getSystemScreenFeatures(screenId: Guid, roleId: Guid): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/RolePermission/GetSystemScreenFeatures/${screenId}/${roleId}`);
  }

  getScreenFeaturesByUrl(path: string): Observable<any[]> {
    return this.http.post<any[]>(`${environment.apiUrl}/RolePermission/GetScreenFeaturesByUrl`, { path: path });
    //return new Observable<boolean>(p => { setTimeout(function () { p.next(true) }, 100); });
  }

  setFeaturePermission(screenId: Guid, roleId: Guid, feature: FeatureNode): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/RolePermission/SetFeaturePermission/${screenId}/${roleId}`, feature);
  }
}
