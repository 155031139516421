import { Guid } from 'guid-typescript';
import { Menu } from './../menu.model';
import { RolepermissionService } from './../../../../pages/RolePermission/rolepermission.service';
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { AppSettings } from '../../../../app.settings';
import { Settings } from '../../../../app.settings.model';
import { MenuService } from '../menu.service';

@Component({
  selector: 'app-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuService]
})
export class VerticalMenuComponent implements OnInit {
  @Input('menuItems') menuItems;
  @Input('menuParentId') menuParentId;
  parentMenu: Array<any>;
  public settings: Settings;
  constructor(public appSettings: AppSettings,
    public menuService: MenuService,
    public rolepermissionService: RolepermissionService) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.menuService.getVerticalMenuItems().then((menuItems) => {
      this.menuItems = menuItems;
      this.parentMenu = this.menuItems.filter(item => item.parentId == this.menuParentId);
    });
  }

  onClick(menuId) {
    this.menuService.toggleMenuItem(menuId);
    this.menuService.closeOtherSubMenus(this.menuItems, menuId);
  }

}
