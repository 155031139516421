import { Guid } from 'guid-typescript';
import { AppSettings } from 'src/app/app.settings';
import { Settings } from './../app.settings.model';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { SessionUser } from './session.user';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<SessionUser>;
    public currentUser: Observable<SessionUser>;
    public currentUserSubscription: Observable<Guid>;
    public currentUserService: Observable<Guid>;
    public currentUserRole: Observable<string>;
    public settings: Settings;

    constructor(private http: HttpClient,
        public appSettings: AppSettings,
        private router: Router) {
        this.settings = this.appSettings.settings;
        // If you are confident that the localStorage.getItem() 
        // call can never return null you can use the non-null assertion operator to tell typescript that you know what you are doing:
        //this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser') || '{}'));
        this.currentUserSubject = new BehaviorSubject<SessionUser>(JSON.parse(localStorage.getItem('sessionUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): SessionUser {
        if (this.currentUserSubject.value != null)
            return this.currentUserSubject.value;
        else
            return new SessionUser;
    }

    login(username: string, password: string) {
        return this.http.post<SessionUser>(`${environment.apiUrl}/Account/Token`, { username, password }).pipe(map(user => {
            // login successful if there's a jwt token in the response            
            if (user && user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('sessionUser', JSON.stringify(user));
                localStorage.setItem('currentUserSubscription', user.services[0].service.subscriptionId.toString());
                let currentUserService = localStorage.currentUserService || user.services[0].service.id;
                if (user.services.findIndex(p => p.service.id == currentUserService) == -1) {
                    currentUserService = user.services[0].service.id;
                }
                localStorage.setItem('currentUserService', currentUserService);

                var item = user.services.filter(p => p.service.id == currentUserService)[0];
                localStorage.setItem('currentProfileId', item.profileId.toString());

                var roles = user.roles.filter(p => p.serviceId == currentUserService);
                let currentUserRole = localStorage.currentUserRole || roles[0].name;

                if (roles.findIndex(p => p.name == currentUserRole) == -1) {
                    currentUserRole = roles[0].name;
                }

                localStorage.setItem('currentUserRole', currentUserRole);
                let currentUserRoleId = JSON.parse(localStorage.getItem('sessionUser')).roles.filter(item => item.name == currentUserRole)[0].id;
                localStorage.setItem('currentUserRoleId', currentUserRoleId);

                this.currentUserSubject.next(user);
            }
            return user;
        }));
    }

    logout() {
        this.settings.sidenavIsOpened = true;
        // remove user from local storage to log user out
        var returnUrl = localStorage.returnUrl;
        localStorage.clear();

        this.currentUserSubject.next(null);

        if (returnUrl) {
            window.location.href = returnUrl;
        } else {
            this.router.navigate(['/login']);
        }
    }

    getCurrentUserRole(): Observable<any[]> {
        return this.http.get<any[]>(`${environment.apiUrl}/Account/me`);
    }

    getMyServices(): Observable<any[]> {
        return this.http.get<any[]>(`${environment.apiUrl}/Service/GetUserServices`);
    }

    handlePermission(path: string): Observable<boolean> {
        return this.http.post<boolean>(`${environment.apiUrl}/RolePermission/HandlePermission`, { path: path });
        //return new Observable<boolean>(p => { setTimeout(function () { p.next(true) }, 100); });
    }


}