import { Guid } from 'guid-typescript';
import { Menu } from './../menu.model';
import { RolepermissionService } from './../../../../pages/RolePermission/rolepermission.service';
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { AppSettings } from '../../../../app.settings';
import { Settings } from '../../../../app.settings.model';
import { MenuService } from '../menu.service';

@Component({
  selector: 'app-horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuService]
})
export class HorizontalMenuComponent implements OnInit {
  @Input('menuParentId') menuParentId;
  public menuItems: Array<any>;
  public settings: Settings;
  constructor(public appSettings: AppSettings,
    public menuService: MenuService,
    public rolepermissionService: RolepermissionService) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.menuItems = this.menuService.getHorizontalMenuItems();
    this.menuItems = this.menuItems.filter(item => item.parentId == this.menuParentId);
    //this.getMenuItemsByRoleId();
  }

  // getMenuItemsByRoleId() {
  //   let currentUserRole = localStorage.getItem('currentUserRole');
  //   let currentUserRoleId = JSON.parse(localStorage.getItem('sessionUser')).roles.filter(item => item.name == currentUserRole)[0].id;
  //   let menuArray = [];
  //   this.rolepermissionService.getMenuItemsByRoleId(currentUserRoleId).subscribe(result => {
  //     if (result) {
  //       menuArray.push(new Menu(Guid.parse('3F6C3144-946B-43CA-A08F-C943F7081529'.toString()), 'Exams', null, null, 'computer', null, true, Guid.parse('00000000-0000-0000-0000-000000000000')));

  //       for (let index = 0; index < result.length; index++) {
  //         menuArray.push(new Menu(
  //           result[index].id,
  //           result[index].title,
  //           result[index].routerLink,
  //           result[index].href,
  //           result[index].icon,
  //           result[index].target,
  //           result[index].hasSubMenu,
  //           result[index].parentId));
  //       }

  //       this.menuItems = menuArray;
  //       var parentId: string = this.menuParentId.toLowerCase();
  //       this.menuItems = this.menuItems.filter(item => item.parentId == parentId);
  //     }
  //   });
  // }
}