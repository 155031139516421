export const environment = {
  production: true,
  systemUrl: '/core/',
  //systemUrl: 'https://localhost:5001/',
  get apiUrl(): string {
    return this.systemUrl + 'api'
  },
  get examSignalRUrl(): string {
    return this.systemUrl + 'examSignalR'
  },
  get chatSignalRUrl(): string {
    return this.systemUrl + 'chatSignalR'
  }
};
